<template>
  <v-card>
    <v-card-title>{{ $vuetify.lang.t("$vuetify.create_event") }}</v-card-title>
    <v-card-text class="px-10">
      <v-form ref="form" v-model="valid">
        <v-layout row wrap class="py-3">
          <v-flex xs12>
            <v-text-field
              :label="$vuetify.lang.t('$vuetify.event_title')"
              required
              :rules="[rules.requiredRule]"
              v-model="object.title"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="my-1 mb-3">
          <v-subheader class="black--text subheading ml-0 pl-0">{{
            $vuetify.lang.t("$vuetify.attendees")
          }}</v-subheader>
          <v-flex xs12>
            <template v-for="attendee in object.attendees">
              <v-chip
                :key="attendee._id"
                close
                text-color="black"
                outlined
                label
                @click:close="remove(attendee)"
                color="grey lighten-2"
              >
                <h4>{{ attendee.full_name }}</h4>
                &nbsp;&nbsp;
                <span class="grey--text caption" v-if="attendee.role"
                  >({{ attendee.role | roleFilter }})</span
                >
              </v-chip>
            </template>
            <v-btn
              icon
              large
              text
              color="secondary"
              @click.stop="selectAttendees = true"
              outlined
              class="ml-3"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </v-flex>
        </v-layout>
        <!-- additional attendees -->
        <div style="margin:1rem -12px ">
          <p class="black--text subheading ml-0 pl-0">
            Add Additional Email
          </p>
          <div class="d-flex align-center">
            <v-text-field
              class="pt-0 mt-0"
              :label="$vuetify.lang.t('$vuetify.email')"
              v-model="additionalEmail"
              type="email"
              hide-details
            ></v-text-field>
            <v-btn @click="addAdditionalEmail" color="secondary" class="mx-3"
              >Add</v-btn
            >
          </div>
        </div>
        <v-layout row wrap class="my-2">
          <v-flex xs12 sm4>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  slot="activator"
                  :label="$vuetify.lang.t('$vuetify.date')"
                  :rules="[rules.requiredRule]"
                  v-model="object.date"
                  prepend-icon="mdi-calendar-month"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="object.date"
                no-title
                scrollable
                actions
                autosave
              >
              </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm4>
            <v-menu
              :close-on-content-click="false"
              v-model="menu2"
              transition="scale-transition"
              offset-y
              :nudge-right="40"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  slot="activator"
                  :label="$vuetify.lang.t('$vuetify.from')"
                  v-model="object.from"
                  :rules="[rules.requiredRule]"
                  prepend-icon="mdi-clock-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker v-model="object.from" autosave></v-time-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm4>
            <v-menu
              :close-on-content-click="false"
              v-model="menu3"
              transition="scale-transition"
              offset-y
              :nudge-right="40"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  slot="activator"
                  :label="$vuetify.lang.t('$vuetify.to')"
                  :rules="[rules.requiredRule]"
                  v-model="object.to"
                  prepend-icon="mdi-clock-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker v-model="object.to" autosave></v-time-picker>
            </v-menu>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <v-text-field
              :label="$vuetify.lang.t('$vuetify.location')"
              required
              :rules="[rules.requiredRule]"
              v-model="object.location"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <user-tagging @tribute-replaced="userTagged">
              <textarea
                slot="input"
                :label="$vuetify.lang.t('$vuetify.details')"
                contenteditable
                v-model="object.details"
                placeholder="@..."
              ></textarea>
            </user-tagging>
          </v-flex>
        </v-layout>

        <div class="mt-5  text-right">
          <v-btn
            large
            outlined
            text
            color="secondary"
            class="mr-5"
            @click="cancel"
          >
            {{ $vuetify.lang.t("$vuetify.cancel") }}
          </v-btn>
          <v-btn
            :loading="loadingState"
            large
            dark
            color="secondary"
            @click="submit"
          >
            {{ $vuetify.lang.t("$vuetify.create") }}
          </v-btn>
        </div>
      </v-form>
    </v-card-text>

    <v-dialog v-model="selectAttendees" max-width="350px">
      <v-card max-height="500px" class="overflow-auto">
        <v-card-title class="title">
          {{ $vuetify.lang.t("$vuetify.employees") }}
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item v-for="employee in employee_list" :key="employee._id">
              <v-list-item-action>
                <v-checkbox
                  v-model="employee.selected"
                  color="blue"
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ employee.full_name }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  employee.role | roleFilter
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-layout row class="mt-3">
            <v-spacer />
            <v-flex>
              <v-btn
                small
                color="secondary"
                outlined
                text
                @click.stop="selectAttendees = !selectAttendees"
                class="mx-3"
                >{{ $vuetify.lang.t("$vuetify.cancel") }}</v-btn
              >
              <v-btn small color="secondary" dark @click.stop="addAttendees">{{
                $vuetify.lang.t("$vuetify.add")
              }}</v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import UserTagging from "@/components/widgets/user-tagging";
import { googleOAuth, signUserOut } from "@/firebase";

import { mapMutations, mapState } from "vuex";
import moment from "moment";

import { requiredRule } from "@/helpers/form-rules";
import { validateEmail } from "@/helpers";
export default {
  name: "create-event",
  props: {
    application: {
      type: Object,
      required: true,
    },
  },
  components: {
    UserTagging,
  },
  data() {
    return {
      valid: false,
      loading: false,
      loadingState: false,
      search: null,
      menu: false,
      menu2: false,
      menu3: false,
      selectAttendees: false,
      employee_list: [],
      selectedAttendees: [],

      object: {
        // event: {
        title: "",
        attendees: [],
        date: "",
        from: null,
        to: null,
        location: "",
        details: "",
        application_id: null,
        // }
      },
      taggedUsers: [],
      additionalEmail: "",
      rules: {
        requiredRule,
      },
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    this.object.application_id = this.application._id;
    this.searchEmployee();
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    async submit() {
      if (!this.valid) {
        return;
      }

      const {
        date,
        to,
        from,
        attendees,
        location,
        title,
        details,
        // application_id
      } = this.object;

      const event = {
        start: {
          dateTime: moment(`${date} ${from}`).format(),
        },
        end: {
          dateTime: moment(`${date} ${to}`).format(),
        },
        attendees: attendees.map((att) => {
          return { email: att.email };
        }),

        summary: title,
        description: details,
        location,
      };
      this.createEvent(event);
    },
    async postTags() {
      let tagged_ids = this.taggedUsers.map((user) => user._id);
      let tagObject = {
        application_id: this.application_id,
        candidate_id: this.candidate_id,
        type: "event",
        tagged_users: tagged_ids,
      };

      try {
        await this.axios.post("tag", tagObject);
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    addAttendees() {
      this.object.attendees = this.selectedAttendees.filter(
        (attendee) => attendee.selected === true
      );
      setTimeout(() => (this.selectAttendees = false), 1000);
    },
    cancel() {
      if (this.$refs.form) this.$refs.form.reset();
      this.$emit("close-popup");
      this.object.notes = "";
    },
    remove(item) {
      this.object.attendees.splice(this.object.attendees.indexOf(item), 1);
      this.object.attendees = [...this.object.attendees];
    },
    async searchEmployee() {
      try {
        const resp = await this.axios.get(
          `/user?company_id=${this.user.company_id}`
        );
        const empList = resp.data.data;
        this.employee_list = empList;
        this.selectedAttendees = empList;
        for (let index = 0; index < this.selectedAttendees.length; index++) {
          this.selectedAttendees[index].selected = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    userTagged(event) {
      this.taggedUsers.push(event.original);
      this.object.details += "";
    },
    checkTaggedUsers() {
      this.taggedUsers.map((mention, i) => {
        if (!this.object.details.includes(`@${mention.first_name}`)) {
          this.$delete(this.taggedUsers, i);
        }
      });
    },
    async createEvent(resource) {
      try {
        this.loadingState = true;
        const { google_access_token } = this.user;
        let access_token = google_access_token;
        let google_meet_link = "";

        const CALENDAR_ID = "primary";
        if (!access_token) {
          const { token, user } = await googleOAuth();
          const updatedUser = {
            ...this.user,
            google_access_token: token,
            google_authentication_email: user.email,
          };
          await this.axios.put(`user/${this.user._id}`, updatedUser);
          this.SET_USER(updatedUser);
          access_token = token;
        }

        window.gapi.client.setToken({ access_token });
        const resp = await window.gapi.client.calendar.events.insert(
          {
            calendarId: CALENDAR_ID,
            resource,
            sendNotifications: true,
          },
          {
            conferenceDataVersion: 1,
          }
        );

        const eventPatch = {
          conferenceData: {
            createRequest: {
              requestId: Math.random()
                .toString()
                .replace("0.", ""),
              conferenceSolutionKey: { type: "hangoutsMeet" },
            },
          },
        };

        const resp2 = await window.gapi.client.calendar.events.patch({
          calendarId: CALENDAR_ID,
          eventId: resp.result.id,
          resource: eventPatch,
          sendNotifications: true,
          conferenceDataVersion: 1,
        });

        if (resp2.result.hangoutLink)
          google_meet_link = resp2.result.hangoutLink;
        // saving the calendar to the database
        await this.axios.post("/events", {
          ...this.object,
          event_id: resp.result.id,
          google_meet_link,
        });

        this.checkTaggedUsers();
        if (this.taggedUsers.length) {
          await this.postTags();
        }
        this.cancel();
        this.$emit("refresh");
        this.loadingState = false;
      } catch (error) {
        console.log(error);
        // remove user token
        await signUserOut();
        const updatedUser = {
          ...this.user,
          google_access_token: "",
        };
        await this.axios.put(`user/${this.user._id}`, updatedUser);
        this.SET_USER(updatedUser);
        this.loadingState = false;
        console.log(error);
      }
    },
    addAdditionalEmail() {
      if (!validateEmail(this.additionalEmail)) {
        return this.$notify({
          group: "backend-error",
          title: "Error",
          text: "Please Enter a valid email",
          type: "error",
          duration: 5000,
        });
      }

      this.object.attendees.push({
        _id: Math.random(),
        full_name: this.additionalEmail,
        email: this.additionalEmail,
        additional: true,
      });

      this.additionalEmail = null;
    },
  },
};
</script>

<style lang="scss">
.bordered {
  border: 1px solid #ddd;
  border-radius: 0.2em;
}
#textarea .input-group__input {
  border: 1px solid !important;
}
</style>
