<template>
  <Card
    :title="$vuetify.lang.t('$vuetify.candidate_status')"
    bg-color="#fff"
    header-color="#f3f3f3"
    elevation="1"
    :loading="loading"
  >
    <div class="status--list">
      <div
        v-for="(status, index) in statuses"
        :key="status"
        @click="updateStatus(status)"
      >
        <div
          class="status--list-item"
          :class="application.status === status ? 'active' : ''"
        >
          <v-icon class="icon-flag">mdi-flag</v-icon>
          <p class="status--list-item-title">{{ status }}</p>
        </div>
        <div class="text-center" v-if="statuses.length - 1 !== index">
          <v-icon>mdi-arrow-down</v-icon>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "candidate-status",
  props: {
    application: { type: Object, required: true },
    loading: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["user"]),
    statuses() {
      return this.user.company.stages;
    },
  },
  methods: {
    iconColor(status) {
      if (status === "done") return "#fff";
      else if (status === "processing") return "#33aada73";
      else return;
    },
    updateStatus(status) {
      this.$emit("update-status", status);
    },
  },
};
</script>

<style lang="scss" scoped>
.status--list {
  padding: 0;
  margin: 0;
  list-style: none;

  &-item {
    border: 1px solid transparent;
    padding: 0.5rem;
    border-radius: 5px;
    position: relative;
    margin: 10px 0;
    cursor: pointer;
    transition: all 0.3s linear;

    &::v-deep .icon-flag {
      color: transparent;
    }

    &.active {
      background: #33aada;
      color: #fff;

      &::v-deep .icon-flag {
        color: #fff;
      }
    }

    &:hover {
      border-color: #33aada;
      color: #33aada;
      background: #fff;

      &::v-deep .icon-flag {
        color: #33aada73;
      }
    }

    &-title {
      text-align: center;
      text-transform: capitalize;
      color: inherit;
    }

    .icon-flag {
      position: absolute;
      left: 10px;
    }
  }
}
</style>
