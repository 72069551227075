<template>
  <Card
    :title="$vuetify.lang.t('$vuetify.email_correspondences')"
    id="correspondences"
    no-padding
    bg-color="transparent"
    header-color="#f3f3f3"
    elevation="1"
  >
    <template v-slot:header-action>
      <v-btn text color="secondary" @click="$emit('replay-email')"
        ><v-icon>mdi-mail</v-icon> {{ $vuetify.lang.t("$vuetify.reply") }}
      </v-btn>
    </template>

    <v-expand-transition>
      <div class="accordion" v-if="formatedEmails.length">
        <div
          class="accordion--item"
          v-for="email in formatedEmails"
          :key="email.id"
        >
          <div
            class="accordion--item-header"
            @click="makeSelection(email.id)"
            v-ripple
          >
            <div>
              <v-icon color="secondary">{{
                active == email.id ? "mdi-close" : "mdi-plus"
              }}</v-icon>
              <p class="subject secondary--text ml-2">
                {{ $vuetify.lang.t("$vuetify.subject") }}
                <strong>{{ email.subject }}</strong>
              </p>
            </div>
            <v-spacer></v-spacer>
            <div>
              <p class="secondary--text mr-2">{{ email.date }}</p>
              <v-icon color="secondary">{{
                email.isSend
                  ? "mdi-arrow-right-bottom-bold"
                  : "mdi-arrow-left-top-bold"
              }}</v-icon>
            </div>
          </div>
          <div class="accordion--item-body">
            <v-expand-transition>
              <div
                class="accordion--item-content"
                v-if="email.id === active"
                v-html="email.html"
              ></div>
            </v-expand-transition>
          </div>
        </div>
      </div>

      <div v-else class="pa-5">
        <p class="subtitle-1 text-center">
          {{ $vuetify.lang.t("$vuetify.no_emails_at_moment") }}
        </p>
      </div>
    </v-expand-transition>
  </Card>
</template>

<script>
import { UTC_TO_LOCAL } from "@/helpers";
export default {
  name: "candidate-correspondences",
  props: {
    emails: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      active: "",
    };
  },
  computed: {
    formatedEmails() {
      return this.emails.map((email) => {
        const { _id, message, type, subject, created_at } = email;

        return {
          subject,
          date: UTC_TO_LOCAL(created_at, "lll"),
          isSend: type === "outgoing",
          html: message,
          id: _id,
        };
      });
    },
  },
  methods: {
    makeSelection(id) {
      if (id === this.active) this.active = "";
      else this.active = id;
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  &--item {
    &-header {
      padding: 1rem;
      display: flex;
      align-items: center;
      cursor: pointer;

      div {
        display: flex;
        align-items: center;
      }
    }

    &-body {
      border-bottom: 1px solid #ccc;
    }

    &-content {
      padding: 1rem;
      margin-bottom: 10px;
      text-align: left;
    }
  }
}
</style>
