<template>
  <v-card :loading="loading">
    <v-card-title>
      {{ $vuetify.lang.t(`$vuetify.${edit ? "edit" : "add"}`) }}
      {{ $vuetify.lang.t("$vuetify.evaluation") }}
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="evaluation.form"
        class="no-highlight"
      >
        <template v-slot:[`item.assigned_points`]="{ item }">
          <v-text-field v-model="item.assigned_points" required type="number">
          </v-text-field>
        </template>

        <template v-slot:[`item.comments`]="{ item }">
          <v-text-field class="" v-model="item.comments"> </v-text-field>
        </template>
      </v-data-table>
      <v-flex>
        <v-textarea
          v-model="feedback"
          :label="$vuetify.lang.t('$vuetify.evaluation_details.feedback')"
        >
        </v-textarea>
      </v-flex>
      <div class="buttons">
        <v-btn
          outlined
          @click="recommendation = 'rejected'"
          :color="recommendation == 'rejected' ? 'secondary' : 'dark'"
          ><span> {{ $vuetify.lang.t("$vuetify.rejected") }} </span></v-btn
        >
        <v-btn
          outlined
          :color="recommendation == 'hold' ? 'secondary' : 'dark'"
          @click="recommendation = 'hold'"
        >
          {{ $vuetify.lang.t("$vuetify.on_hole") }}
        </v-btn>
        <v-btn
          outlined
          :color="recommendation == 'interview' ? 'secondary' : 'dark'"
          @click="recommendation = 'interview'"
        >
          {{ $vuetify.lang.t("$vuetify.schedule_next_interview") }}
        </v-btn>
        <v-btn
          outlined
          :color="recommendation == 'hire' ? 'secondary' : 'dark'"
          @click="recommendation = 'hire'"
          >{{ $vuetify.lang.t("$vuetify.recommended_for_hire") }}</v-btn
        >
      </div>
      <div class="text-right">
        <v-btn color="error" outlined @click="close(false)" class="mx-5">
          {{ $vuetify.lang.t("$vuetify.cancel") }}
        </v-btn>
        <v-btn :loading="loading" @click="saveEvaluation" dark color="blue">
          {{ $vuetify.lang.t("$vuetify.save") }}
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import get from "lodash/get";
export default {
  name: "add-evaluation",
  props: ["application", "current_evaluation", "edit"],
  data() {
    return {
      valid: false,
      active: "blue",
      loadingState: false,
      loading: false,
      search: null,
      headers: [
        { text: this.$vuetify.lang.t("$vuetify.criteria"), value: "criteria" },
        {
          text: this.$vuetify.lang.t("$vuetify.total_rating"),
          value: "points",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.assigned_rating"),
          value: "assigned_points",
        },
        { text: this.$vuetify.lang.t("$vuetify.comments"), value: "comments" },
      ],
      evaluation: {
        form: [],
      },
      feedback: "",
      recommendation: "rejected",
    };
  },

  mounted() {
    if (this.current_evaluation && this.current_evaluation.id) {
      this.evaluation = { ...this.current_evaluation };
      this.feedback = get(this.evaluation, "feedback", "");
      this.recommendation = get(this.evaluation, "recommendation", "");
      // this.evaluation.form.splice(this.evaluation.form.length - 1, 1);
    } else if (this.application) {
      const { job_post } = this.application;
      if (job_post && job_post.evaluation_id) this.getEvaluationForm();
    }
  },
  methods: {
    async getEvaluationForm() {
      try {
        this.loading = true;
        const resp = await this.axios.get(
          `/evaluation-form/${this.application.job_post.evaluation_id}`
        );
        this.evaluation = resp.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    changeRecommendation(val) {
      this.recommendation = val;
      if (this.edit) {
        this.evaluation.recommendation = val;
      }
    },
    calculateTotal() {
      let total = 0;
      let percentage = 0;
      let assigned = 0;

      for (let i = 0; i < this.evaluation.form.length; i++) {
        total += parseInt(this.evaluation.form[i].points);
        assigned += parseInt(this.evaluation.form[i].assigned_points);
        percentage = (assigned / total) * 100;
      }
      return percentage;
    },
    async editEvaluation() {
      try {
        this.loading = true;
        this.evaluation.updated_at = Date.now();
        this.evaluation.recommendation = this.recommendation;
        this.evaluation.feedback = this.feedback;

        const all_evaluations = [...this.application.evaluations];
        let index = all_evaluations.findIndex((e) => {
          return e.date == this.evaluation.date;
        });

        if (index !== -1) {
          all_evaluations[index] = this.evaluation;
          await this.axios.post("update/evaluations", {
            id: this.evaluation.id,
            all_evaluations,
          });
        }
        this.close();
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async saveEvaluation() {
      if (this.edit) {
        this.editEvaluation();
        return;
      }
      let evaluation = {
        form: this.evaluation.form,
        date: Date.now(),
        interviewer: this.$store.state.user.full_name,
        recommendation: this.recommendation,
        feedback: this.feedback,
        user_id: this.$store.state.user._id,
        id: this.application._id,
      };
      evaluation.percentage = this.calculateTotal();

      try {
        this.loading = true;
        await this.axios.post("update/evaluations", {
          id: this.application._id,
          evaluation,
        });
        this.close();
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    close(refresh = true) {
      this.$emit("close-popup");
      if (refresh) this.$emit("refresh");
      this.loading = false;
      this.evaluation = {
        form: [],
        feedback: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.bordered {
  border: 1px solid #ddd;
  border-radius: 0.2em;
}
.outline {
  border: 1px solid !important;
}
.input-group__input {
  border: 1px solid !important;
}
.active-text {
  color: "blue";
}
.no-highlight tr:hover {
  background: white !important;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}
</style>
