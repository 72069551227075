<template>
  <div>
    <v-data-table
      :headers="tableHeader"
      :items="formatedList"
      :loading="loading"
      item-key="id"
      height="200"
      :loading-text="$vuetify.lang.t('$vuetify.loading_please_wait')"
      :no-results-text="$vuetify.lang.t('$vuetify.no_data_found')"
      :no-data-text="$vuetify.lang.t('$vuetify.no_data_available')"
      class="elevation-1 mb-5"
    >
      <template v-slot:top>
        <div class="table-header">
          <h5 class="mr-4">{{ $vuetify.lang.t("$vuetify.logs") }}</h5>
        </div>
      </template>

      <template v-slot:[`item.activity`]="{ item }">
        <div>
          {{ item.activity.text }}

          <span
            v-if="item.activity.link"
            class="link secondary--text"
            v-clipboard="item.activity.link"
            v-clipboard:success="clipboardSuccessHandler"
            v-clipboard:error="clipboardErrorHandler"
          >
            <span>{{ item.activity.link }} </span>
            <v-icon color="secondary">mdi-content-copy</v-icon>
          </span>
        </div>
      </template>
    </v-data-table>

    <v-snackbar
      :timeout="2000"
      v-model="snackbar"
      :color="isError ? 'error' : 'success'"
    >
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import { UTC_TO_LOCAL } from "@/helpers";

import { mapState } from "vuex";

export default {
  props: {
    logs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      totalCount: this.logs.length,
      currentPage: 1,
      limit: 10,
      snackbar: false,
      snackbarText: "",
      isError: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    paginationOptions() {
      return { page: this.currentPage, itemsPerPage: this.limit };
    },
    formatedList() {
      return this.logs
        .map((log) => {
          const { _id, slug, created_at } = log;

          return {
            id: _id,
            activity: {
              text: slug,
            },
            date: UTC_TO_LOCAL(created_at, "lll"),
          };
        })
        .reverse();
    },
    tableHeader() {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.activity"),
          align: "start",
          value: "activity",
          sortable: true,
          divider: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.time_and_date"),
          value: "date",
          sortable: true,
        },
      ];
    },
  },
  methods: {
    clipboardSuccessHandler() {
      this.isError = false;
      this.snackbar = true;
      this.snackbarText = "Link Copied Successfully.";
    },
    clipboardErrorHandler() {
      this.isError = true;
      this.snackbar = true;
      this.snackbarText = "SOmething Went wrong while copying the link";
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f3f3f3;
  padding: 1rem;

  .text-field {
    max-width: 200px;
  }
}

h5 {
  font-weight: 500;
}

.link {
  cursor: pointer;
  span {
    text-decoration: underline;
  }
}
</style>
