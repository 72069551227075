<template>
  <v-card class="pb-3 overflow-hidden">
    <v-card-title>
      <h3 class="grey--text mb-5">
        {{ $vuetify.lang.t("$vuetify.delete_candidate") }}
      </h3>
      <v-spacer />
      <v-btn icon text @click="$emit('close-popup')"
        ><v-icon class="grey--text">mdi-close</v-icon></v-btn
      >
    </v-card-title>
    <v-card-text>
      <v-layout row wrap>
        <v-flex xs12>
          <p class="red--text body-2 text-xs-left px-3">
            {{ $vuetify.lang.t("$vuetify.popup.delete_candidate.danger_text") }}
          </p> </v-flex
        ><br />
        <v-flex xs12>
          <p class="body-2 text-xs-left px-3 mt-5">
            {{
              $vuetify.lang.t("$vuetify.popup.delete_candidate.proceed_text")
            }}
          </p>
        </v-flex>
        <v-flex xs5 class="mt-2 mx-3">
          <v-text-field
            name="confirm-delete"
            :label="$vuetify.lang.t('$vuetify.type_here')"
            id="delete"
            v-model="deleteConfirmInput"
          ></v-text-field>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions>
      <v-layout row justify-space-center align-center class="text-xs-center">
        <v-flex xs12>
          <v-btn
            large
            color="error"
            class="mx-3"
            @click="onConfirmDelete"
            :disabled="btnDisabled"
            :loading="loading"
            >{{ $vuetify.lang.t("$vuetify.delete") }}</v-btn
          >
          <v-btn
            large
            text
            outlined
            color="secondary"
            class="mx-3"
            @click="$emit('close-popup')"
          >
            {{ $vuetify.lang.t("$vuetify.cancel") }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: { application: { type: Object, required: true } },
  name: "delete-candidate",
  data() {
    return {
      loading: false,
      deleteConfirmInput: "",
    };
  },
  computed: {
    btnDisabled() {
      return this.deleteConfirmInput != "DELETE";
    },
  },

  methods: {
    async onConfirmDelete() {
      try {
        this.loading = true;
        await this.axios.delete(`/application/${this.application._id}`);
        this.$router.replace("/application");
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
