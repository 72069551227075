<template>
  <div class="data-table">
    <v-data-table
      :headers="tableHeader"
      :items="formatedInterviews"
      :loading="loading"
      item-key="id"
      height="200"
      :loading-text="$vuetify.lang.t('$vuetify.loading_please_wait')"
      :no-results-text="$vuetify.lang.t('$vuetify.no_data_found')"
      :no-data-text="$vuetify.lang.t('$vuetify.no_data_available')"
      class="elevation-1 mb-5"
    >
      <template v-slot:top>
        <div class="table-header">
          <h5 class="mr-4">{{ $vuetify.lang.t("$vuetify.interviews") }}</h5>
        </div>
      </template>

      <template v-slot:[`item.attendees`]="{ item }">
        <div style="white-space: pre">
          {{ item.attendees }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// import moment from "moment";
import { mapState } from "vuex";
export default {
  props: {
    application: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    paginationOptions() {
      return { page: this.currentPage, itemsPerPage: this.limit };
    },
    formatedInterviews() {
      const { events } = this.application;
      if(!events) return [];
      return events.map((event) => {
        const { _id, attendees, details, date, from, posted_by } = event;
        let users = []

          if(attendees && attendees.length){
            users = attendees.map((att) => att.full_name);
          }

        return {
          postedBy: posted_by.full_name,
          date: date + " " + from,
          attendees: users.join("\n"),
          details,
          id: _id,
        };
      }).reverse();
    },
    tableHeader() {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.posted_by"),
          align: "start",
          value: "postedBy",
          sortable: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.time_and_date"),
          value: "date",
          sortable: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.attendees"),
          value: "attendees",
          sortable: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.details"),
          value: "details",
        },
      ];
    },
  },
  methods: {
    async fetchMails() {},
  },
  created() {
    this.fetchMails();
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f3f3f3;
  padding: 1rem;

  .text-field {
    max-width: 200px;
  }
}

h5 {
  font-weight: 500;
}

.data-table {
  &::v-deep tr td {
    vertical-align: baseline;
  }
}
</style>
