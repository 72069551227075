<template>
  <Card
    :title="$vuetify.lang.t('$vuetify.candidate_info')"
    hideDownBtn
    bg-color="#fff"
    header-color="#f3f3f3"
    elevation="1"
  >
    <template v-slot:header-action v-if="isManager">
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, i) in items" :key="i" @click="item.action">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <!-- conatent start here -->

    <h2>{{ candidate.full_name }}</h2>

    <div class="detail-container">
      <ul class="detail-list">
        <li class="detail-list-item">
          <v-icon class="icon">mdi-account</v-icon>
          <p>{{ jobTitle }}</p>
        </li>
        <li class="detail-list-item">
          <v-icon class="icon">mdi-flag</v-icon>
          <p>{{ candidate.experience || "-" }}</p>
        </li>
      </ul>

      <ul class="detail-list">
        <li class="detail-list-item">
          <v-icon class="icon">mdi-phone</v-icon>
          <p>{{ candidate.phone }}</p>
        </li>
        <li class="detail-list-item">
          <v-icon class="icon" color="secondary">mdi-mail</v-icon>
          <a class="secondary--text" @click="isManager ?  $emit('replay-email') : ''">{{
            candidate.email
          }}</a>
        </li>
      </ul>
    </div>

    <div class="d-flex align-items-center justify-content-between">
      <div>
        <v-btn v-if="isManager" outlined color="secondary" @click="$emit('add-notes')">
          {{ $vuetify.lang.t("$vuetify.add_note") }}
        </v-btn>
        <v-btn
          outlined
          color="secondary"
          class="mx-2"
          @click="$emit('add-evaluation')"
        >
          {{ $vuetify.lang.t("$vuetify.add_evaluation") }}
        </v-btn>
        <v-btn v-if="isManager"  outlined color="secondary" @click="$emit('create-event')">
          {{ $vuetify.lang.t("$vuetify.create_event") }}
        </v-btn>
      </div>
      <div>
        <a
          v-if="cv"
          class="secondary--text decoration-none"
          :href="cv"
          target="_blank"
          download
        >
          <v-icon class="icon" color="secondary">mdi-download</v-icon>
          {{ $vuetify.lang.t("$vuetify.resume") }}</a
        >

        <a
          v-if="coverLetter"
          class="secondary--text"
          :href="coverLetter"
          target="_blank"
          download
        >
          <v-icon class="icon" color="secondary">mdi-download</v-icon>
          {{ $vuetify.lang.t("$vuetify.cover_letter") }}</a
        >
      </div>
    </div>
  </Card>
</template>

<script>
export default {
  name: "candidate-info",
  props: {
    candidate: {
      type: Object,
      required: true,
    },
    cv: {
      type: String,
    },
    coverLetter: {
      type: String,
    },
    jobTitle: {
      type: String,
    },
    isManager:{
      type:Boolean,
      default:false
    },
    isAdmin:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      items: [
        {
          title: this.$vuetify.lang.t("$vuetify.initiate_auto_interview"),
          action: () => this.$emit("interview-confirmation"),
        },
        {
          title: this.$vuetify.lang.t("$vuetify.delete_candidate"),
          action: () => this.$emit("delete-candidate"),
        },
        {
          title: this.$vuetify.lang.t("$vuetify.edit_candidate"),
          action: () => this.$emit("edit-candidate"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.detail-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin: 1rem 0;

  .detail-list {
    padding: 0;
    list-style: none;

    &:not(:last-child) {
      border-right: 1px dashed rgba(0, 0, 0, 0.54);
    }

    &-item {
      padding: 0.5rem 0;
      display: flex;
      align-items: center;
      .icon {
        margin-right: 10px;
      }

      p {
        margin: 0;
      }
    }
  }
}
</style>
