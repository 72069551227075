<template>
  <div class="v-tribute">
    <slot name="input"></slot>
  </div>
</template>

<script>
import Tribute from "tributejs";

export default {
  name: "user-tagging",
  data() {
    return {
      tribute: null,
      el: null,
      open: false,
      users: [],
    };
  },
  mounted() {
    this.el = this.$slots.input[0].elm;
    this.tribute = new Tribute({
      values: (text, cb) => {
        this.fetchFromAPI(text, (users) => cb(users));
      },
      lookup: function(user) {
        return user.first_name + user.last_name;
      },
      fillAttr: "first_name",
    });

    this.tribute.attach(this.el);

    this.el.addEventListener("tribute-replaced", (e) => {
      this.$emit("tribute-replaced", e.detail.item);
    });

    this.el.addEventListener("tribute-no-match", (e) => {
      this.$emit("tribute-no-match", e);
    });
  },
  methods: {
    async fetchFromAPI(text, cb) {
      try {
        const resp = await this.axios.get(`/company/users?search=${text}`);
        const users = resp.data.data;
        cb(users);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
.v-tribute {
  width: 100%;
}
// input[type='text'],
.content-editable {
  appearance: none;
  border: none;
  background: #eee;
  padding: 1rem 1rem;
  color: #666;
  width: 100%;
  border-radius: 0.25rem;
  font-size: 16px;
  outline: none;
  &:focus {
    background: #fff;
  }
}
.content-editable:empty:before {
  content: attr(placeholder);
  display: block;
  color: #666;
}
textarea {
  appearance: none;
  border: none;
  background: #eee;
  padding: 1rem;
  width: 100%;
  border-radius: 0.25rem;
  font-size: 16px;
  height: 100px;
  outline: none;
  &:focus {
    background: #fff;
  }
}

// Tribute-specific styles
.tribute-container {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  max-height: 300px;
  max-width: 500px;
  overflow: auto;
  display: block;
  z-index: 999999 !important;

  ul {
    margin: 0;
    margin-top: 2px;
    padding: 0;
    list-style: none;
    background: #efefef;
  }

  li {
    padding: 5px 5px;
    cursor: pointer;

    &.highlight,
    &:hover {
      background: #ddd;
    }

    span {
      font-weight: bold;
    }

    &.no-match {
      cursor: default;
    }
  }

  .menu-highlighted {
    font-weight: bold;
  }
}
.highlight {
  background: var(--primary-base) !important;
  font-weight: bold;
}
</style>
