<template>
    <v-card class="pb-3 overflow-hidden">
      <v-card-title>
        <v-spacer />
      </v-card-title>
      <v-card-text class="mb-5">
        <v-layout row wrap>
          <v-flex xs12>
            <p class="body-2 text-xs-left px-3">
              {{ $vuetify.lang.t("$vuetify.popup.auto_interview.information_text") }}
            </p> </v-flex
          ><br />
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-layout row justify-space-center align-center class="text-xs-center">
          <v-flex xs12>
            <v-btn
              large
              color="secondary"
              class="mx-3"
              @click="onConfirmDelete"
              :loading="loading"
              >{{ $vuetify.lang.t("$vuetify.send") }}</v-btn
            >
            <v-btn
              large
              text
              outlined
              color="secondary"
              class="mx-3"
              @click="$emit('close-popup')"
            >
              {{ $vuetify.lang.t("$vuetify.cancel") }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  export default {
    props: { application: { type: Object, required: true } },
    name: "interview-confirmation",
    data() {
      return {
        loading: false,
      };
    },
  
    methods: {
      async onConfirmDelete() {
        try {
          this.loading = true;
          await this.axios.post('/interview-invitation', {application_id: this.application._id});
        //   if(response.success){
        //   this.loading = false;
        //   }
        //   this.$router.replace("/application");
          this.loading = false;
          this.$emit('close-popup')
        } catch (error) {
          console.log(error);
          this.loading = false;
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  