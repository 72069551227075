<template>
  <Card
    :title="$vuetify.lang.t(`$vuetify.${title.toLowerCase()}`)"
    :id="id"
    bg-color="#fff"
    header-color="#f3f3f3"
    elevation="1"
  >
    <div style="height: 70vh;" v-if="cv">
      <vue-pdf-app
        v-if="isPDF"
        page-scale="page-actual"
        :pdf="cv"
      ></vue-pdf-app>

      <VueDocPreview v-else :value="cv" type="office" />
    </div>
  </Card>
</template>

<script>
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

import VueDocPreview from "vue-doc-preview";
export default {
  name: "candidate-resume",
  components: { VuePdfApp, VueDocPreview },
  props: {
    cv: {
      type: String
    },
    title: { type: String, default: "Resume" },
    id: { type: String, default: "resume" }
  },
  computed: {
    isPDF() {
      const arr = this.cv.split(".");
      const extension = arr[arr.length - 1];
      return extension.toLowerCase() === "pdf";
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss">
.pdf-app .toolbar {
  z-index: 1 !important;
}
</style>
