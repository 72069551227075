<template>
  <loader :loading="loading" height="100vh">
    <v-row v-if="application" class="application">
      <v-col cols="12" sm="12" md="9">
        <v-row>
          <v-col cols="12" sm="12" :lg="SHOW_AI_SCORE && isAdmin ? '8' : '12'">
            <CandidateInfo
              class="candidate-info"
              :candidate="application.candidate"
              :cv="application.cv"
              :cover-letter="application.cover_letter"
              :job-title="application.job_post.title"
              :is-manager="isManager"
              :is-admin="isAdmin"
              @edit-candidate="dialogHandler('edit-candidate')"
              @delete-candidate="dialogHandler('delete-candidate')"
              @interview-confirmation="dialogHandler('interview-confirmation')"
              @replay-email="dialogHandler('reply-email')"
              @add-notes="dialogHandler('add-notes')"
              @add-evaluation="dialogHandler('add-evaluation')"
              @create-event="dialogHandler('create-event')"
            />
          </v-col>
          <v-col cols="12" sm="12" lg="4" v-if="SHOW_AI_SCORE && isAdmin">
            <AIScore
              :score="application.ai_score"
              @calculate-ai="calculateAI"
              :loading="AIloading"
              class="ai-score"
            />
          </v-col>
        </v-row>

        <div class="sub-routes">
          <a
            v-for="link in subRoutes"
            class="link"
            :key="link.to"
            @click="$vuetify.goTo(link.to)"
            >{{ link.label }}</a
          >
        </div>

        <!-- resume -->
        <CandidateResume :cv="application.cv" />

        <CandidateResume
          title="Cover Letter"
          id="cover-letter"
          v-if="application.cover_letter"
          :cv="application.cover_letter"
        />
        <!-- questionaire -->
        <Questionaire
          v-if="isManager"
          :questions="application.questions || []"
        />
        <!-- notes -->
        <notes
          v-if="isManager"
          :notes="application.notes"
          @add-notes="dialogHandler('add-notes')"
          @edit-note="(note) => dialogHandler('add-notes', note)"
        />
        <!-- evaulations -->
        <Evaluation
          :evaluations="allEvaluations"
          @edit-evaluation="editEvaluation"
        />
        <!-- email correspondences -->
        <EmailCorrespondences
          v-if="isManager"
          :emails="application.emails"
          @replay-email="dialogHandler('reply-email')"
        />
        <!-- interviews -->
        <Interviews v-if="isManager" :application="application" />
        <!-- events -->
        <Events
          v-if="isManager"
          :application="application"
          :events="eventList"
        />
        <!-- logs -->
        <Logs v-if="isManager" :logs="application.logs" />
      </v-col>

      <v-col cols="12" sm="12" md="3">
        <CandidateStatus
          v-if="isManager"
          :application="application"
          @update-status="updateStatus"
          :loading="statusLoading"
        />

        <Card
          title="Source"
          no-padding
          bg-color="#fff"
          header-color="#f3f3f3"
          elevation="1"
        >
          <v-list color="transparent">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>
                  {{
                    application.source === "website"
                      ? "mdi-web"
                      : "mdi-account-circle"
                  }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <!-- {{ application.source }} -->
                  {{
                    application.referal[0]
                      ? application.referal[0].employ_name
                      : "website"
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </Card>

        <Card
          title="All Applied Jobs"
          no-padding
          class="applied-jobs"
          bg-color="#fff"
          header-color="#f3f3f3"
          elevation="1"
          v-if="allAppliedJobs.length"
        >
          <v-list color="transparent">
            <v-list-item
              v-for="(job, i) in allAppliedJobs"
              :key="i"
              @click="$router.push(`/application/${job._id}`)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ job.job_post.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </Card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" :max-width="popupMaxWidth">
      <component
        :key="dialog"
        :is="popupComponent"
        @close-popup="dialog = false"
        @refresh="fetchApplication"
        :edit="editMode"
        :edit_note="editNote"
        :application="application"
        :current_evaluation="current_evaluation"
      />
    </v-dialog>
  </loader>
</template>

<script>
import CandidateStatus from "@/components/applicant/candidate-status";
import AIScore from "@/components/applicant/ai-engine-score";
import CandidateInfo from "@/components/applicant/candidate-info";
import CandidateResume from "@/components/applicant/candidate-resume";
import EmailCorrespondences from "@/components/applicant/email-correspondences";
import Evaluation from "@/components/applicant/evaluation";
import Events from "@/components/applicant/events";
import Interviews from "@/components/applicant/interviews";
import Logs from "@/components/applicant/logs";
import Notes from "@/components/applicant/notes";
import Questionaire from "@/components/applicant/questionaire";
import PopupComponents from "@/components/applicant/popup/index.js";

import { CANDIDATE_SUB_ROUTES } from "@/constants";
import snakeCase from "lodash/snakeCase";
import userRestriction from "@/mixins/user-restriction.js";
import { mapGetters } from "vuex";

export default {
  name: "applicant-profile",
  mixins: [userRestriction],
  components: {
    CandidateStatus,
    AIScore,
    CandidateInfo,
    Questionaire,
    Notes,
    Logs,
    Interviews,
    Events,
    Evaluation,
    EmailCorrespondences,
    CandidateResume,
  },
  data() {
    return {
      application: null,
      allAppliedJobs: [],
      eventList: [],
      // loading states
      loading: true,
      AIloading: false,
      statusLoading: false,
      // popup related
      dialog: "",
      popupComponent: null,
      popupMaxWidth: "600px",
      current_evaluation: null,
      editMode: false,
      editNote: {},
    };
  },
  computed: {
    ...mapGetters(["SHOW_AI_SCORE"]),
    subRoutes() {
      return CANDIDATE_SUB_ROUTES.map((route) => {
        return {
          ...route,
          label: this.$vuetify.lang.t(`$vuetify.${snakeCase(route.label)}`),
        };
      }).filter((r) => r.roles.includes(+this.user.role));
    },
    allEvaluations() {
      if (this.application) {
        return this.application.evaluations || [];
      }
      return [];
    },
    isManager() {
      if (this.isAdmin) return true;
      if (this.application) {
        const { job_post } = this.application;
        const { hiring_manager, hr_coordinator } = job_post;
        // hiring_manager === interviewer
        if (Array.isArray(hr_coordinator)) {
          const index = hr_coordinator.findIndex((hr) => hr === this.user._id);
          if (index !== -1) return true;
        } else {
          if (typeof hr_coordinator === "string")
            return this.user._id === hr_coordinator;
        }

        if (this.user._id === hiring_manager) return false;
      }
      return false;
    },
  },
  methods: {
    goToWebsite() {
      const { link } = this.application.job_post;
      if (!link) return;
      window.open(link, "_blank");
    },
    async fetchApplication(showLoading = true) {
      try {
        if (showLoading) this.loading = true;
        const resp = await this.axios.get(
          `/application/${this.$route.params.id}?w=candidate,jobPost,notes,events,emails,events,logs,referal,calendar`
        );

        const application = resp.data;
        application.questions = JSON.parse(application.questions);
        this.application = application;

        const resp2 = await this.axios.get(
          `/application?w=candidate,jobPost&candidate_id=${application.candidate_id}`
        );
        const allApplications = resp2.data.data;
        this.allAppliedJobs = allApplications.filter(
          (app) => app._id != this.$route.params.id
        );

        await this.fetchAllCalendar(this.application.calendar);
        this.removeAllLoadingStates();
      } catch (error) {
        this.removeAllLoadingStates();
        console.log(error);
      }
    },
    async calculateAI() {
      try {
        this.AIloading = true;
        await this.axios.post("/calculate/ai_score", {
          application_id: this.application._id,
        });
        this.fetchApplication(false);
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async updateStatus(status) {
      try {
        this.statusLoading = true;
        await this.axios.put(`/application/${this.application._id}`, {
          status,
        });
        this.fetchApplication(false);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchAllCalendar(calendar) {
      try {
        if (calendar && calendar.length) {
          const { google_access_token, _id } = this.user;
          const userCalendar = calendar.filter((c) => c.user_id === _id);
          let access_token = google_access_token;
          let events = [];

          if (access_token) {
            window.gapi.client.setToken({ access_token });
            const resp = await window.gapi.client.calendar.events.list({
              calendarId: "primary",
              showDeleted: false,
              singleEvents: true,
              orderBy: "startTime",
            });
            const eventList = resp.result.items;

            for (let i = 0; i < userCalendar.length; i++) {
              const event_id = userCalendar[i].event_id;
              const event = eventList.find((e) => e.id === event_id);
              if (event) events.push(event);
            }
          }

          this.eventList = events.reverse();
        }
      } catch (error) {
        this.$notify({
          group: "backend-error",
          title: "Error",
          text: "Something went wrong please re-authenticate with the google to get the events in profile.",
          type: "error",
          duration: 8000,
        });
        console.log(error);
      }
    },
    dialogHandler(type, props = {}) {
      this.dialog = type;
      if (type === "add-evaluation") this.popupMaxWidth = "900px";
      if (type === "add-notes") this.editNote = props;
      this.popupComponent = PopupComponents[type];
    },
    removeAllLoadingStates() {
      this.AIloading = false;
      this.loading = false;
      this.statusLoading = false;
    },
    editEvaluation(evaluation) {
      this.current_evaluation = evaluation;
      this.editMode = true;
      this.dialogHandler("add-evaluation");
    },
  },
  created() {
    this.fetchApplication();

    // setTimeout(() => {
    //   window.gapi.client.calendar.events
    //     .list({
    //       calendarId: this.$route.params.id,
    //       showDeleted: false,
    //       singleEvents: true,
    //       orderBy: "startTime"
    //     })
    //     .then(resp => {
    //       console.log(resp);
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // });
  },
  watch: {
    "$route.params.id"(val, old) {
      if (val !== old) this.fetchApplication();
    },
    dialog(val) {
      if (!val) {
        this.current_evaluation = null;
        this.editMode = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.applied-jobs {
  .v-list-item {
    min-height: 30px;

    &:nth-child(even) {
      background: #cccccc59;
    }

    &__content {
      padding: 5px 0;
    }
  }
}

.sub-routes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 0;
  flex-wrap: wrap;

  .link {
    display: inline-block;
    padding: 0.5rem 1rem;
    text-decoration: underline;
  }
}

.application {
  .ai-score {
    height: 100%;
    margin-bottom: 0;
  }

  .candidate-info {
    margin-bottom: 0;
  }
}
</style>
