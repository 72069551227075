<template>
  <v-card class="overflow-hidden">
    <v-card-title>
      {{ $vuetify.lang.t("$vuetify.send_message") }}
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-layout row class="px-1">
          <v-flex xs12>
            <v-select
              v-bind:items="templates"
              item-text="subject"
              item-value="value"
              v-model="selectedTemplate"
              @input="subjectSelected"
              :loading="loading"
              return-object
              required
              :label="$vuetify.lang.t('$vuetify.subject')"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="px-1">
          <v-flex xs12>
            <vue-html5-editor
              v-if="object"
              @change="saveMessage"
              :content="object.message"
              :height="300"
            ></vue-html5-editor>
          </v-flex>

          <v-flex xs12 class="ma-1">
            <input
              type="file"
              multiple
              ref="fileInput"
              :name="'uploadFieldName'"
              @change="filesChange($event)"
              class="file-input d-none"
            />
            <p class="my-1">{{ $vuetify.lang.t("$vuetify.attachments") }}</p>
            <div class="file-upload-box" @click="$refs.fileInput.click()">
              <div class="text-sm-center">
                <v-icon class="pointer" color="secondary" slot="activator"
                  >mdi-upload</v-icon
                >
                <h3 class="text-lightblue pointer">
                  {{ $vuetify.lang.t("$vuetify.click_here") }}
                </h3>
              </div>
            </div>
            <div v-if="files">
              <div
                class="file-uploaded my-2 cl"
                v-for="(file, index) in files"
                :key="index"
              >
                <v-icon class="pdf_icon">mdi-file-pdf-box</v-icon>
                {{ file.name | limitChar }}
                <v-btn @click="remove(index)" icon
                  ><v-icon>mdi-close-circle-outline</v-icon></v-btn
                >
              </div>
            </div>
          </v-flex>
        </v-layout>
        <div class="mt-10 text-right">
          <v-btn large outlined color="secondary" class="mr-5" @click="cancel">
            Cancel
          </v-btn>
          <v-btn
            :loading="loadingState"
            large
            color="secondary"
            @click="submit"
          >
            Send
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import * as _ from "lodash";
import { mapState } from "vuex";
export default {
  name: "reply-email",
  props: {
    application: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      loadingState: false,
      files: [],
      attachments: [],
      loading: false,
      search: null,
      templates: [],
      selectedTemplate: {},
      object: {
        message: "",
        subject: "",
        company_id: null,
        application_id: null,
        type: "outgoing",
      },
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    this.object.application_id = this.application._id;
    this.object.company_id = this.user.company_id;
    this.getTemplates();
  },
  methods: {
    previewFiles() {
      this.files = this.$refs.myFiles.files;
    },
    saveMessage(e) {
      this.object.message = e;
    },
    async getTemplates() {
      try {
        this.loading = true;
        const resp = await this.axios.get(
          `/templates?company_id=${this.user.company_id}`
        );

        this.templates = resp.data.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async submit() {
      // if (this.object.notes.length > 0) {
      if (!this.object.subject || !this.object.message) {
        return;
      }

      try {
        this.loadingState = true;
        let data = new FormData();
        data = this.setData(data);

        await this.axios.post("emails", data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        this.cancel();
        this.$emit("refresh");
        this.loadingState = false;
      } catch (error) {
        this.loadingState = false;
        console.log(error);
      }
    },
    setData(data) {
      data.append("message", this.object.message);
      data.append("subject", this.object.subject);
      data.append("company_id", this.object.company_id);
      data.append("application_id", this.object.application_id);
      data.append("type", this.object.type);
      _.forEach(this.files, (file) => {
        data.append("attachments[]", file);
      });
      return data;
    },
    subjectSelected() {
      this.object.subject = this.selectedTemplate.subject;
      this.object.message = this.selectedTemplate.message;
    },
    cancel() {
      this.$refs.form.reset();
      this.object.notes = "";
      this.object.message = "";
      this.$emit("close-popup");
    },
    remove(index) {
      this.files.splice(index, 1);
    },
    filesChange(e) {
      _.forEach(e.target.files, (file) => {
        this.files.push(file);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bordered {
  border: 1px solid #ddd;
  border-radius: 0.2em;
}
.outline {
  border: 1px solid !important;
}
.input-group__input {
  border: 1px solid !important;
}
.file-upload-box {
  border: 2px dashed #829299;
  background-color: #f7f7f7;
  min-height: 100px;
  max-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-input {
  opacity: 0;
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}
.vue-html5-editor > .toolbar > ul {
  padding: 5px;
}
.pointer {
  cursor: pointer;
}
.file-uploaded {
  justify-content: space-between;
  display: flex;
  align-items: center;
  .pdf_icon {
    font-size: 40px;
    color: #da2c2c;
  }
}
</style>
