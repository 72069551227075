<template>
  <Card
    :title="$vuetify.lang.t('$vuetify.evaluation')"
    id="evaluation"
    class="evaluation"
    bg-color="#fff"
    header-color="#f3f3f3"
    elevation="1"
  >
    <div class="evaluations" v-if="formatedEvaluations.length">
      <div
        class="evaluations--item "
        v-for="(evaluation, index) in formatedEvaluations"
        :key="index"
      >
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <h4>
              {{ $vuetify.lang.t("$vuetify.interview_by") }} :
              {{ evaluation.interviewer }}
            </h4>
            <p>
              {{ $vuetify.lang.t("$vuetify.date") }}:
              <strong>{{ evaluation.date }}</strong>
            </p>
          </div>

          <v-btn v-if="evaluation.canEdit" icon @click="editEvaluation(index)">
            <v-icon color="secondary">mdi-pencil</v-icon>
          </v-btn>
        </div>

        <v-data-table
          :headers="tableHeader"
          :items="evaluation.form"
          hide-default-footer
          item-key="id"
          class="elevation-0 my-5"
        >
          <template v-slot:[`item.assigned_points`]="{ item }">
            <v-icon color="warning" v-for="n in 5" :key="n">{{
              calculateRating(item.stars, n) ? "mdi-star-outline" : "mdi-star"
            }}</v-icon>
            ({{ item.assigned_points }})
          </template>

          <template v-slot:[`item.comments`]="{ item }">
            {{ item.comments || "-" }}
          </template>
        </v-data-table>

        <h3>{{ $vuetify.lang.t("$vuetify.evaluation_details.feedback") }}</h3>
        <p style="white-space: pre-wrap;">
          {{ evaluation.feedback }}
        </p>

        <h3 class="mt-5">{{ $vuetify.lang.t("$vuetify.recommendation") }}:</h3>
        <v-btn color="secondary">{{ evaluation.recommendation }}</v-btn>
      </div>
    </div>

    <div v-else class="">
      <p class="subtitle-1 text-center">
        {{ $vuetify.lang.t("$vuetify.no_evaluation_yet") }}
      </p>
    </div>
  </Card>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "candidate-evaluation",
  props: {
    evaluations: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState(["user"]),
    formatedEvaluations() {
      return this.evaluations.map(evaluation => {
        const { user_id } = evaluation;
        const canEdit = this.user._id == user_id;
        let recommendation = evaluation.recommendation;

        if (recommendation === "hold") recommendation = "on hold";
        if (recommendation === "interview")
          recommendation = "Schedule next interview ";
        if (recommendation === "hire") recommendation = "Recommended for Hire";

        return {
          ...evaluation,
          date: moment(evaluation.date).format("lll"),
          canEdit,
          recommendation,
          form: evaluation.form.map(f => {
            let stars = +f.assigned_points;

            stars = stars > 10 ? 10 : stars;
            return {
              ...f,
              stars
            };
          })
        };
      });
    },
    tableHeader() {
      return [
        {
          text: "Criteria",
          align: "start",
          value: "criteria"
        },
        {
          text: "Rating",
          value: "assigned_points"
        },
        { text: "Comments", value: "comments" }
      ];
    }
  },
  methods: {
    editEvaluation(index) {
      const e = this.evaluations[index];
      this.$emit("edit-evaluation", e);
    },
    calculateRating(star, index) {
      return star < index;
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.evaluation ::v-deep tbody tr {
  &:nth-child(even) {
    background: #cccccc59;
  }
}

.evaluations {
  &--item {
    margin-bottom: 3rem;
    &:not(:first-child) {
      padding-top: 1rem;
      border-top: 1px solid #333;
    }
  }
}
p {
  font-size: 14px;
}
</style>
