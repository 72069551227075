<template>
  <v-data-table
    :headers="tableHeader"
    :items="formattedList"
    :loading="loading"
    item-key="id"
    height="200"
    :loading-text="$vuetify.lang.t('$vuetify.loading_please_wait')"
    :no-results-text="$vuetify.lang.t('$vuetify.no_data_found')"
    :no-data-text="$vuetify.lang.t('$vuetify.no_data_available')"
    class="elevation-1 mb-5"
    id="notes"
  >
    <template v-slot:top>
      <div class="table-header">
        <h5 class="mr-4">{{ $vuetify.lang.t("$vuetify.notes") }}</h5>

        <v-btn text color="secondary" @click="$emit('add-notes')">
          <v-icon small>mdi-plus</v-icon>
          {{ $vuetify.lang.t("$vuetify.add_note") }}</v-btn
        >
      </div>
    </template>

    <!-- <template v-slot:[`item.actions`]="{ item }"> -->
    <template v-slot:[`item.notes`]="{ item }">
      <div
        class="d-flex notes"
        style="white-space: pre-wrap"
        v-html="item.notes"
      ></div>
    </template>

    <!-- TODO:: will add the button for edit the notes -->
    <template v-slot:[`item.editable`]="{ item }">
      <v-btn v-if="item.editable" icon @click="editNotes(item)">
        <v-icon color="secondary">mdi-pencil</v-icon>
      </v-btn>

      <div v-else>n/a</div>
    </template>
  </v-data-table>
</template>

<script>
import { UTC_TO_LOCAL } from "@/helpers";
import { mapState } from "vuex";
export default {
  props: {
    notes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      totalCount: this.notes.length,
      currentPage: 1,
      limit: 10,
    };
  },
  computed: {
    ...mapState(["user"]),
    paginationOptions() {
      return { page: this.currentPage, itemsPerPage: this.limit };
    },
    formattedList() {
      return this.notes.map((mail) => {
        const { posted_by_name, note, created_at, _id, posted_by } = mail;
        return {
          id: _id,
          postedBy: posted_by_name,
          notes: note,
          date: UTC_TO_LOCAL(created_at, "lll"),
          editable: posted_by._id === this.user._id,
        };
      });
    },
    tableHeader() {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.posted_by"),
          align: "start",
          value: "postedBy",
          sortable: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.time_and_date"),
          value: "date",
          sortable: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.notes"),
          value: "notes",
          width: "50%",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.action"),
          value: "editable",
        },
      ];
    },
  },
  methods: {
    editNotes(item) {
      const note = this.notes.find((n) => n._id === item.id);
      if (!note) return;
      this.$emit("edit-note", note);
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f3f3f3;
  padding: 1rem;

  .text-field {
    max-width: 200px;
  }
}

h5 {
  font-weight: 500;
}
</style>
