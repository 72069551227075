<template>
  <Card
    :title="$vuetify.lang.t('$vuetify.ai_engine_score')"
    bg-color="#fff"
    header-color="#f3f3f3"
    elevation="1"
    :loading="loading"
  >
    <div class="text-center">
      <v-progress-circular
        :rotate="360"
        :size="150"
        :width="5"
        :value="score"
        color="accent"
      >
        <h1>{{ score }}%</h1>
        <p>{{ $vuetify.lang.t("$vuetify.score") }}</p>
      </v-progress-circular>

      <div class="rating mt-3">
        <v-icon color="warning" v-for="n in 5" :key="n">{{ stars(n) }}</v-icon>
      </div>
    </div>

    <v-btn
      icon
      fab
      @click="$emit('calculate-ai')"
      absolute
      style="top:0;right:0;"
    >
      <v-icon color="secondary">mdi-refresh</v-icon>
    </v-btn>
  </Card>
</template>

<script>
export default {
  name: "ai-engine-score",
  props: {
    score: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  methods: {
    stars(index) {
      return index <= Math.floor(this.score / 20)
        ? "mdi-star"
        : "mdi-star-outline";
    },
  },
};
</script>

<style lang="scss" scoped>
.text-center {
  &::v-deep .v-progress-circular__info {
    display: block;
  }
}
</style>
