<template>
  <v-card>
    <v-card-title>{{
      $vuetify.lang.t(`$vuetify.${edit_note._id ? "edit" : "add"}_note`)
    }}</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-layout row class="px-3">
          <v-flex xs12>
            <user-tagging
              @tribute-replaced="userTagged"
              @tribute-no-match="noTribute"
            >
              <textarea
                slot="input"
                contenteditable
                v-model="object.note"
                placeholder="@..."
              ></textarea>
            </user-tagging>
          </v-flex>
        </v-layout>
        <div class="mt-5 text-right">
          <v-btn
            large
            outlined
            color="secondary"
            class="mr-5"
            text
            @click="cancel"
          >
            {{ $vuetify.lang.t("$vuetify.cancel") }}
          </v-btn>
          <v-btn
            :loading="loadingState"
            large
            color="secondary"
            @click="submit"
          >
            {{ $vuetify.lang.t(`$vuetify.${edit_note._id ? "edit" : "add"}`) }}
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import UserTagging from "@/components/widgets/user-tagging";
export default {
  name: "add-notes",
  props: {
    application: {
      type: Object,
      required: true
    },
    edit_note: { type: Object, default: () => ({}) }
  },
  components: { UserTagging },
  data() {
    return {
      valid: false,
      loadingState: false,
      loading: false,
      search: null,
      object: {
        note: "",
        application_id: null
      },
      taggedUsers: []
    };
  },
  mounted() {
    this.object.application_id = this.application._id;
    if (this.edit_note._id) {
      this.object.id = this.edit_note._id;
      this.object.note = this.edit_note.note;
    }
  },
  methods: {
    async submit() {
      if (!this.object.note) {
        return;
      }

      try {
        this.loadingState = true;
        this.checkTaggedUsers();
        if (this.taggedUsers.length) {
          await this.postTags();
        }

        if (!this.edit_note._id) {
          await this.createNote();
        } else {
          await this.updateNote();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.cancel();
        this.$emit("refresh");
        this.loadingState = false;
      }
    },

    async createNote() {
      return this.axios.post("/notes", this.object);
    },
    async updateNote() {
      return this.axios.post("/update/notes", {
        ...this.object
      });
    },

    async postTags() {
      try {
        let tagged_ids = this.taggedUsers.map(user => user._id);
        let tagObject = {
          application_id: this.application._id,
          candidate_id: this.application.candidate_id,
          type: "note",
          tagged_users: tagged_ids
        };
        await this.axios.post("tag", tagObject);
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    userTagged(event) {
      this.taggedUsers.push(event.original);
      this.object.note += "";
    },
    checkTaggedUsers() {
      this.taggedUsers.map((mention, i) => {
        if (!this.object.note.includes(`@${mention.first_name}`)) {
          this.$delete(this.taggedUsers, i);
        }
      });
    },
    noTribute() {
      console.log("No Tribute");
    },
    cancel() {
      this.$refs.form.reset();
      this.object.notes = "";
      this.$emit("close-popup");
    }
  }
};
</script>

<style lang="scss" scoped>
.bordered {
  border: 1px solid #ddd;
  border-radius: 0.2em;
}
.outline {
  border: 1px solid !important;
}
.input-group__input {
  border: 1px solid !important;
}
</style>
