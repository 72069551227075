// popup components
import AddEvaluation from "@/components/applicant/popup/add-evaluation";
import AddNotes from "@/components/applicant/popup/add-notes";
import CreateEvent from "@/components/applicant/popup/create-event";
import DeleteCandidate from "@/components/applicant/popup/delete-candidate";
import InterviewConfirmation from "@/components/applicant/popup/interview-confirmation";
import EditCandidate from "@/components/applicant/popup/edit-candidate";
import ReplyEmail from "@/components/applicant/popup/reply-email";

export default {
  "add-evaluation": AddEvaluation,
  "add-notes": AddNotes,
  "interview-confirmation": InterviewConfirmation,
  "create-event": CreateEvent,
  "delete-candidate": DeleteCandidate,
  "edit-candidate": EditCandidate,
  "reply-email": ReplyEmail,
};
