<template>
  <Card
    :title="$vuetify.lang.t('$vuetify.events')"
    id="events"
    noPadding
    bg-color="#fff"
    header-color="#f3f3f3"
    elevation="1"
  >
    <div class="event" v-if="formatedEvents.length">
      <div
        class="event--item elevation-1"
        v-for="(event , index) in formatedEvents"
        :key="index"
      >
        <p class="title">
          {{ $vuetify.lang.t("$vuetify.title") }}: {{ event.summary }}
        </p>

        <ul class="event--item-details">
          <li>
            <p>
              {{ $vuetify.lang.t("$vuetify.start_time") }}:
              <strong> {{ formatDate(event.start.dateTime) }} </strong>
            </p>
            <p>
              {{ $vuetify.lang.t("$vuetify.end_time") }}:
              <strong>{{ formatDate(event.end.dateTime) }}</strong>
            </p>
          </li>

          <li v-for="attendee in event.attendees" :key="attendee.email">
            <p>{{ attendee.email }}</p>
            <p :class="`status ${getStatusInfo(attendee.responseStatus).color}--text`">
              <v-icon
                :color="getStatusInfo(attendee.responseStatus).color"
                class="mr-3"
                >{{ getStatusInfo(attendee.responseStatus).icon }}</v-icon
              >
              {{ getStatusInfo(attendee.responseStatus).text }}
            </p>
          </li>
        </ul>

        <div class="event--footer">
          <div class="pa-3">
            {{ event.description }}
          </div>
          <div
            class="link secondary--text"
            v-clipboard="event.hangoutLink"
            v-clipboard:success="clipboardSuccessHandler"
            v-clipboard:error="clipboardErrorHandler"
          >
            <div>
              <v-img
                src="@/assets/google-meet.png"
                height="30px"
                width="30px"
                contain
              ></v-img>
              <span>{{ event.hangoutLink }} </span>
            </div>
            <v-icon color="secondary">mdi-content-copy</v-icon>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="pa-5">
      <p class="subtitle-1 text-center">
        {{ $vuetify.lang.t("$vuetify.no_events_at_moment") }}
      </p>
    </div>

    <!-- <template #footer>
      <div class="pagination">
        <div class="footer-select">
          Items per page
          <v-select
            v-model="pagination.limit"
            :items="['5', '10', '15', 'all']"
            single-line
            dense
            hide-details
          ></v-select>
        </div>

        <p class="pagination-text">1-1 of 1</p>

        <v-btn icon class="mr-3">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </template> -->

    <v-snackbar
      :timeout="2000"
      v-model="snackbar"
      :color="isError ? 'error' : 'success'"
    >
      {{ snackbarText }}
    </v-snackbar>
  </Card>
</template>

<script>
import moment from 'moment'
export default {
  name: "candidate-events",
  props: {
    application: {
      type: Object,
      required: true
    },
    events:{
      type:Array,default:() => ([])
    }
  },
  data() {
    return {
      pagination: {
        limit: "5"
      },
      snackbar: false,
      snackbarText: "",
      isError: false
    };
  },
  computed: {
    formatedEvents() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // const applicationEvents = this.application.events;
      return this.events;
      // return this.events.reduce((acc, current) => {
      //   const index = applicationEvents.findIndex(
      //     e => e.title === current.summary
      //   );
      //   let event = {};
      //   if (index !== -1) {
      //     event = applicationEvents[index];
      //     const googleMeet = current.hangoutLink;
      //     const attendees = event.attendees.map(attende => {
      //       const idx = current.attendees.findIndex(
      //         a => a.email === attende.email
      //       );
      //       if (idx !== -1) {
      //         return {
      //           ...attende,
      //           status: current.attendees[idx].responseStatus
      //         };
      //       } else {
      //         return {};
      //       }
      //     });

      //     event.attendees = attendees;
      //     event.googleMeet = googleMeet;
      //   }
      //   return [...acc, event];
      // }, []);
    }
  },
  methods: {
    formatDate(date){
      if(date){
        return moment(date).format('lll')
      }
      return date;
    },
    clipboardSuccessHandler() {
      this.isError = false;
      this.snackbar = true;
      this.snackbarText = "Link Copied Successfully.";
    },
    clipboardErrorHandler() {
      this.isError = true;
      this.snackbar = true;
      this.snackbarText = "SOmething Went wrong while copying the link";
    },

    getStatusInfo(status) {
      if (status === "accepted")
        return {
          color: "success",
          icon: "mdi-check",
          text: "Accepted"
        };
      else if (status === "declined")
        return {
          color: "error",
          icon: "mdi-close-circle",
          text: "Rejected"
        };
      else
        return {
          color: "warning",
          icon: "mdi-account-clock-outline",
          text: "Pending"
        };
    }
  },
  created() {
  }
};
</script>

<style lang="scss" scoped>
.event {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-height: 400px;
  overflow-y: auto;
  padding: 1rem 0;

  &--item {
    margin: 0.5rem;
    p.title {
      font-size: 16px !important;
      padding: 1rem;
    }

    &-details {
      padding: 0;
      list-style: none;

      li {
        display: flex;
        align-items: center;
        padding: 0.5rem;

        &:nth-child(odd) {
          background: #f3f3f3;
        }
        p {
          width: 50%;
          font-size: 12px;
          padding: 0.5rem;

          &:first-child {
            border-right: 1px dashed rgb(137, 137, 137);
          }
        }
      }
    }
  }

  &--footer {
    .link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      cursor: pointer;

      > div {
        display: flex;
        align-items: center;

        span {
          margin-left: 1rem;
        }
      }
    }
  }
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0.75rem;
  padding: 0 8px;
  background-color: #f3f3f3;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: thin solid rgba(0, 0, 0, 0.12);

  .footer-select {
    margin-left: auto;
    margin-right: 14px;
    display: flex;
    align-items: center;
    flex: 0 0 0;
    justify-content: flex-end;
    white-space: nowrap;

    &::v-deep .v-input {
      margin: 13px 0 13px 34px;
    }
  }

  &-text {
    margin: 0 32px 0 24px;
  }
}
</style>
