<template>
  <Card
    :title="$vuetify.lang.t('$vuetify.questionnaire')"
    id="questionnaire"
    bg-color="#fff"
    header-color="#f3f3f3"
    elevation="1"
  >
    <div class="question" v-if="questions.length">
      <div
        class="question-item"
        v-for="(question, index) in questions"
        :key="index"
      >
        <p class="secondary--text">{{ index + 1 }}. {{ question.question }}</p>
        <p>A. {{ question.answer }}</p>
      </div>
    </div>

    <p class="subtitle-1 text-center" v-else>
      {{ $vuetify.lang.t("$vuetify.no_question_ask") }}
    </p>
  </Card>
</template>

<script>
export default {
  name: "questionnaire",
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      question: "Lorem ipsum dolor sit amet?",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, voluptatum.",
    };
  },
};
</script>

<style lang="scss" scoped>
.question {
  &-item {
    border-radius: 5px;
    &:nth-child(odd) {
      background: #cccccc59;
    }
    padding: 0.4rem 1rem;
  }
}
</style>
